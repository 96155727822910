import React from "react"
import { ContactSection } from "../components/ContactSection"
import { Layout } from "../components/Layout"
import { SEO } from "../components/SEO"
import { TipsSection } from "../components/TipsSection"

const ContactPage = () => (
  <Layout>
    <SEO
      title="Neem contact op"
      description="Heb je interesse of vragen? Neem dan contact op via het contactformulier."
      meta={[{ name: "robots", content: "noindex" }]}
    />

    <ContactSection />

    <TipsSection />
  </Layout>
)

export default ContactPage
